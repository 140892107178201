<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="offer")
			.row
				.col-md-9
					b-tabs(content-class="mt-3", pills, card)
						b-tab(title="Main", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label General Information <span v-if="offer.id">({{ offer.id }})</span>
										.card-body
											.row
												.col-sm-4
													.form-group
														label ID
														input.form-control.readonly(type="text", :value="offer.id", readonly="readonly")
												.col-sm-4
													.form-group
														label Advertiser
														input.form-control.readonly(type="text", :value="advertiser.name", readonly="readonly")
												.col-sm-4
													.form-group
														label Adv. Offer ID
														input.form-control.readonly(type="text", :value="offer.externalOffer ? offer.externalOffer.external_id : ''", readonly="readonly")
											.form-group
												label Name
												input.form-control(type="text", v-model="offer.name")
											.row
												.col-sm-4
													.form-group
														label Status
														select.form-control(v-model="offer.status")
															option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
												.col-sm-4
													.form-group
														label E.Status
														select.form-control(v-model="offer.effective_status")
															option(v-for="o in offerEffectiveStatusOptions", :value="o.v") {{ o.t }}
												.col-sm-4
													.form-group
														label Type
														select.form-control(v-model="offer.type")
															option(v-for="o in offerTypeOptions", :value="o.v") {{ o.t }}

											.row
												.col-sm-12
													b-form-checkbox(v-model="offer.is_locked", switch) Lock API sync (payout, countries, os, events)
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label General Info
										.card-body
											.form-group
												label Campaign Description
												textarea.form-control(placeholder="Campaign description", v-model="offer.description", rows="5")
											.row
												.col-md-6
													.form-group
														label KPI Description
														textarea.form-control(placeholder="KPI", v-model="offer.kpi_description")
												.col-md-6
													.form-group
														b-form-checkbox(v-model="offer.require_device_id", switch) Require Device ID
														b-form-checkbox(v-model="offer.require_appname", switch) Require AppName
														b-form-checkbox(v-model="offer.require_bundle", switch) Require BundleID
														b-form-checkbox(v-model="offer.incent", switch) Allow Incent

											.form-group
												label Preview URL
													=' '
													a(href="javascript:void(0);", @click="loadAssetInfo()")
														i.la.la-refresh
												input.form-control(type="text", placeholder="Preview URL", v-model="offer.preview_url")
											.form-group
												label Asset
												select.form-control(v-model="offer.asset_id")
													option(v-for="o in assetOptions", :value="o.v") {{ o.t }}

								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Payouts
										.card-body
											.row
												.col-xl-4.col-lg-6
													.form-group
														label.d-flex
															span(style="flex: 1") Payout
															span.text-muted Currency
														.input-group
															input.form-control(type="text", v-model="offer.payout")
															select.form-control.text-muted(v-model="offer.currency")
																option(v-for="o in currencyOptions", :value="o.v") {{ o.t }}
														//div(v-if="offer.externalOffer", style="margin-top: 10px;")
															label.d-flex
																span(style="flex: 1") Advertiser Payout
																span.text-muted
																	a(href="javascript:void(0);", @click="copyPayout()") Set payout
															.input-group
																input.form-control(type="text", :value="offer.externalOffer.payout")
																input.form-control.text-muted(v-model="copyPayoutPercent")

												.col-xl-4.col-lg-6
													.form-group
														label Model
														select.form-control(v-model="offer.payout_model")
															option(v-for="o in payoutModelOptions", :value="o.v") {{ o.v }}
											hr
											h5 Events
											table.table.table-striped
												thead
													tr
														th Name
														th Label
														th Payout
														th(width="50")
															button.btn.btn-sm.btn-circle.btn-primary(type="button", @click="addEvent()")
																i.la.la-plus
												tbody
													tr(v-for="(e, i) in offer.events")
														td
															input.form-control(type="text", v-model="e.name")
														td
															input.form-control(type="text", v-model="e.label")
														td
															input.form-control(type="text", v-model="e.payout")
														td
															button.btn.btn-sm.btn-circle.btn-default(type="button", @click="offer.events.splice(i, 1)")
																i.la.la-minus


									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Daily Caps
										.card-body
											.row
												.col-xl-3.col-lg-6
													.form-group
														label Monthly Conversion Cap
														.input-group
															input.form-control(type="text", v-model="offer.monthly_conversion_cap")
											.row
												.col-xl-3.col-lg-6
													.form-group
														label Daily Conversion Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_conversion_cap")
											.row
												.col-xl-4.col-lg-6
													.form-group
														label Daily Click Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_click_cap")
												.col-xl-4.col-lg-6
													.form-group
														label Daily Impression Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_impression_cap")
												.col-xl-4.col-lg-6
													.form-group
														label Click Goal
														.input-group
															input.form-control(type="text", v-model="offer.daily_click_goal")
												.col-xl-4.col-lg-6
													.form-group
														label Impression Goal
														.input-group
															input.form-control(type="text", v-model="offer.daily_impression_goal")
											.row
												.col-xl-4.col-lg-6
													.form-group
														label Override Click Goal
														.input-group
															input.form-control(type="text", v-model="offer.override_click_goal")
												.col-xl-4.col-lg-6
													.form-group
														label Override Impression Goal
														.input-group
															input.form-control(type="text", v-model="offer.override_impression_goal")
														.card.card-custom.gutter-b
									.card-header
										.card-title
											.card-label Pacing
									.card-body
										.row
											.col-xl-3
												.form-group
													label Clicks / Minue
													.input-group
														input.form-control(type="text", v-model="offer.click_pacing_min")
											.col-xl-3
												.form-group
													label Clicks / Hour
													.input-group
														input.form-control(type="text", v-model="offer.click_pacing_hour")
											.col-xl-3
												.form-group
													label Imps / Minue
													.input-group
														input.form-control(type="text", v-model="offer.impression_pacing_min")
											.col-xl-3
												.form-group
													label Imps / Hour
													.input-group
														input.form-control(type="text", v-model="offer.impression_pacing_hour")



						b-tab(title="Tracking Link", style="padding: 0")
							.row
								.col-md-12
									.card.card-custom.gutter-b
										//.card-header
											.card-title
												.card-label Tracking Links
										.card-body
											.row
												.col-md-3.col-sm-6
													.form-group.no-label
														b-form-checkbox(v-model="offer.is_vta", switch, inline) Is VTA?
													.form-group.no-label
														b-form-checkbox(v-model="offer.scan_required", switch, inline) Scan required
													.form-group.no-label
														b-form-checkbox(v-model="offer.direct_link", switch, inline) Is direct link? (MMP / Asset Link)
													.form-group.no-label
														b-form-checkbox(v-model="offer.sign_clicks", switch, inline) Sign Clicks
													.form-group.no-label
														b-form-checkbox(v-model="offer.crossplatform", switch, inline) CrossPlatform
													.form-group.no-label
														b-form-checkbox(v-model="offer.lock_tracking_links", switch, inline) Lock tracking links
												.col-md-3.col-sm-6
													.form-group
														label MMP
														.input-group
															select.form-control(v-model="offer.mmp")
																option(:value="null") - None -
																option(v-for="o in mmpOptions", :value="o.v") {{ o.t }}
												.col-md-3.col-sm-6
													.form-group
														label AdvSub1 postback parameter
														.input-group
															input.form-control(type="text", v-model="offer.adv_sub1_postback")
													.form-group
														b-form-checkbox(v-model="offer.bypass_machine", switch) Bypass Machine
												.col-md-3.col-sm-6
													label Attribution Format
													select.form-control(v-model="offer.attr_format")
														option(v-for="o in attrFormatOptions", :value="o.v") {{ o.t }}
											.row
												.col-md-6
													.form-group
														//label Click URL
														//textarea.form-control(v-model="offer.click_url", rows="3")
														url-input(v-model="offer.click_url", label="Click URL")
												.col-md-6
													.form-group
														//label Impression URL
														url-input(v-model="offer.impression_url", label="Impression URL")
														//textarea.form-control(v-model="offer.impression_url", rows="3")
									.card.card-custom.gutter-b
										.card-body
											.form-group
												label Custom Parameters
												textarea.form-control(v-model="offer.custom_params", rows="5")

						b-tab(title="Targeting", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Informative (Non-restrictive) definitions
										.card-body
											.form-group
												label Countries
												textarea.form-control(rows="3", placeholder="GB, US, ...", v-model="offer.countries")
											.form-group
												label Platform
												select.form-control(v-model="offer.platform")
													option(v-for="o in platformOptions", :value="o.v") {{ o.t }}

							.row
								.col-md-12
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label GEO Restrictions
										.card-body
											.row
												.col-md-6
													h3.widget-section-title Geo Whitelist
													.form-group
														label Countries
														multiselect(:multiple="true", :options="countryOptionsWL", v-model="targeting.geo.whitelist",
															track-by="code2", label="label", deselect-label="", select-label="", @input="targetingOnChangeCountryWL()")

													div.targeting-country-block(v-for="cn in targeting.geo.whitelist")
														h4 [{{cn.code2}}] {{ cn.name }}
														.form-group(v-if="wl_regionOptions[cn.code2]")
															label Regions / States
															multiselect(:multiple="true", :options="wl_regionOptions[cn.code2]", v-model="cn.regions",
																track-by="id", label="label", deselect-label="", select-label="")
														.form-group(v-if="wl_cityOptions[cn.code2]")
															label Cities
															multiselect(:multiple="true", :options="wl_cityOptions[cn.code2]", v-model="cn.cities",
																track-by="id", label="label", deselect-label="", select-label="")
												.col-md-6
													h3.widget-section-title Geo Blacklist
													.form-group
														label Countries
														multiselect(:multiple="true", :options="countryOptionsBL", v-model="targeting.geo.blacklist",
															track-by="code2", label="label", deselect-label="", select-label="", @input="targetingOnChangeCountryBL()")

													div.targeting-country-block(v-for="cn in targeting.geo.blacklist")
														h4 [{{cn.code2}}] {{ cn.name }}
														.form-group(v-if="bl_regionOptions[cn.code2]")
															label Regions / States
															multiselect(:multiple="true", :options="bl_regionOptions[cn.code2]", v-model="cn.regions",
																track-by="id", label="label", deselect-label="", select-label="")
														.form-group(v-if="bl_cityOptions[cn.code2]")
															label Cities
															multiselect(:multiple="true", :options="bl_cityOptions[cn.code2]", v-model="cn.cities",
																track-by="id", label="label", deselect-label="", select-label="")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label ISP Restrictions
										.card-body
											h4.widget-section-title ISP Whitelist
												multiselect(v-model="targeting.isp.whitelist", :options="ispOptions", :multiple="true",
													:max-height="600", :loading="isLoadingISP", label="name", track-by="id",
													:hide-selected="true", :options-limit="300", @search-change="debounceFindISP")
											h4.widget-section-title ISP Blacklist
												multiselect(v-model="targeting.isp.blacklist", :options="ispOptions", :multiple="true",
													:max-height="600", :loading="isLoadingISP", label="name", track-by="id",
													:hide-selected="true", :options-limit="300", @search-change="debounceFindISP")
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Carrier Restrictions
										.card-body
											h4.widget-section-title Carrier Whitelist
												multiselect(v-model="targeting.carrier.whitelist", :options="carrierOptions", :multiple="true",
													:max-height="600", :loading="isLoadingCarrier", label="name", track-by="id",
													:hide-selected="true", :options-limit="300", @search-change="debounceFindCarrier")
											h4.widget-section-title Carrier Blacklist
												multiselect(v-model="targeting.carrier.blacklist", :options="carrierOptions", :multiple="true",
													:max-height="600", :loading="isLoadingCarrier", label="name", track-by="id",
													:hide-selected="true", :options-limit="300", @search-change="debounceFindCarrier")
							.row
								.col-md-12
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Hourly targeting
										.card-body
											b-form-checkbox(v-model="offer.enable_daily_schedule", switch) Hourly targeting
											br
											table.schedule-table
												thead
													tr
														th &nbsp;
														th(v-for="(h,i) in offer.daily_schedule[0]")
															a(href="javascript:void(0)", @click="selectCol(i)") {{ (i < 10 ? '0':'') + i }}
														//th Total
														//th &nbsp;
												tbody
													tr(v-for="(d, j) in offer.daily_schedule")
														td.d
															a(@click="selectRow(j)", href="javascript:void(0)") {{ days[j] }}
														td.c(v-for="(i,h) in d")
															input(type="checkbox", v-model="d[h].enabled")
															//input(type="text", v-model="d[h].clicks")
														//td.col-sum {{ getDailySum(j).toLocaleString() }}
														//td.actions
															a(href="javascript:void(0);", @click="copyAll(j)")
																i.la.la-copy

							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Geo Params
								.card-body
									table.table
										thead
											tr
												th(style="width: 150px;") Countries
												th Params
												th(width="50")
													button.btn.btn-sm.btn-circle.btn-primary(type="button", @click="addGeoParam()")
														i.la.la-plus
										tbody
											tr(v-for="(pr, i) in offer.geo_params")
												td
													input.form-control.form-control-solid(type="text", v-model="pr.country")
												td
													input.form-control.form-control-solid(type="text", v-model="pr.params")
												td
													button.btn.btn-sm.btn-circle.btn-default(type="button", @click="offer.geo_params.splice(i, 1)")
														i.la.la-minus


							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Include/Exclude
								.card-body
									h3 Sub1
									.row
										.col-md-6
											h4 Include
											textarea.form-control.form-control-sm(rows="4", v-model="offer.sub1_include")
										.col-md-6
											h4 Exclude
											textarea.form-control.form-control-sm(rows="4", v-model="offer.sub1_exclude")
									hr
									h3 Sub2
									.row
										.col-md-6
											h4 Include
											textarea.form-control.form-control-sm(rows="4", v-model="offer.sub2_include")
										.col-md-6
											h4 Exclude
											textarea.form-control.form-control-sm(rows="4", v-model="offer.sub2_exclude")
									hr
									h3 Bundle
									.row
										.col-md-6
											h4 Include
											textarea.form-control.form-control-sm(rows="4", v-model="offer.bundle_include")
										.col-md-6
											h4 Exclude
											textarea.form-control.form-control-sm(rows="4", v-model="offer.bundle_exclude")

						b-tab(title="Data Control", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Subsource Caps / Limits
										.card-body
											p.text-muted These reflect on the advertiser side. It is recommended to leave out subsource caps on complex offers.
											.row
												.col-xl-4.col-lg-6
													.form-group
														label Subso§urce Click Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_subsource_click_cap")
												.col-xl-4.col-lg-6
													.form-group
														label Daily Subsource Limit
														.input-group
															input.form-control(type="text", v-model="offer.daily_subsource_limit")
												.col-xl-4.col-lg-6
													.form-group
														label Total Subsource Limit
														.input-group
															input.form-control(type="text", v-model="offer.total_subsource_limit")

								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Override
										.card-body
											.row
												.col-sm-6
													.form-group
														label Data Control Template
															| &nbsp;
															a(href="javascript:void(0);", @click="reloadDataControl()")
																i.la.la-refresh(:class="{'la-spin':busy}")
															| &nbsp;
															router-link(v-if="offer.data_control_id", :to="{name: 'data-control-edit', params: {id: offer.data_control_id}}")
																i.la.la-edit
														v-select(v-model="offer.data_control_id", :reduce="o => o.v", label="t", :options="dataControlOptions")
														//option(value="") None
														//option(v-for="o in dataControlOptions", :value="o.v") {{ o.t }}
														//select.form-control(v-model="offer.data_control_id")
														//	option(value="") None
														//	option(v-for="o in dataControlOptions", :value="o.v") {{ o.t }}
												.col-sm-6
													.form-group
														label Override Attr. Window
														input.form-control(type="text", v-model="offer.override_attribution_window")

											.row
												.col-sm-6
													.form-group
														label Subsource Mode
														select.form-control(v-model="offer.sub1_mode")
															option(:value="null") None
															option(:value="'override'") Override
															option(:value="'override_empty'") Override Empty
															option(:value="'block_empty'") Block Empty
												.col-sm-6
													.form-group
														label Asset Mode
														select.form-control(v-model="offer.asset_mode")
															option(:value="null") None
															option(:value="'override'") Override
															option(:value="'override_empty'") Override Empty
															option(:value="'block_empty'") Block Empty


						b-tab(title="Connected Offers", style="padding: 0", :disabled="!isComplex")
							.row
								.col-md-12
									.card.card-custom.gutter-b
										.card-body
											table.table
												thead
													tr
														th(style="width: 40px;")
															button.btn-secondary.btn-secondary.btn-circle(type="button", @click="addConnectedOffer()")
																i.la.la-plus
														th Offer
														th(style="width: 80px;") Weight
														th(style="width: 80px;") Goals
														//th(style="width: 80px;") Default
														th(style="width: 140px;") &nbsp;
												tbody
													tr(v-for="(o, i) in offer.subOffers")
														td.text-center
															b-form-checkbox(v-model="o.enabled", switch)
														td
															select-offer(v-model="o.child", :multiple="false", :wrapped="false")
															//v-select(:options="offerOptions", v-model="o.child_id", :reduce="op => op.id",
															//	:getOptionLabel="op => op.id + ' - ' + op.status",  @search="fetchOfferOptions")
															//	template(v-slot:option="option")
															//		span [{{ option.id }}] {{ option.name }}
															//v-select(:options="offerOptions", v-model="o.child_id", label="name", @search="fetchOfferOptions", :getOptionLabel="op => op.id + ' - ' + op.status")
																template(slot="option" slot-scope="option")
																	span [{{ option.id }}] {{ option.name }}
														td
															input.form-control(type="text", v-model="o.weight")
														td
															div(v-if="o.child") {{ o.child.daily_click_goal.toLocaleString() }} / {{ o.child.daily_impression_goal.toLocaleString() }}
														//td.text-center
															b-form-checkbox(v-model="o.is_default", switch)
														td
															router-link.btn.btn-sm.btn-secondary(v-if="o.child", :to="{name:'offer-edit', params:{id: o.child.id}}")
																i.la.la-search
															router-link.btn.btn-sm.btn-secondary(v-if="o.child", :to="{name:'reports', query:{offer_id: o.child.id}}")
																i.la.la-bar-chart
															button.btn.btn-sm.btn-secondary(type="button", @click="offer.subOffers.splice(i,1)")
																i.la.la-times
									.form-group
										label Geo Block mode
										select.form-control(v-model="offer.geo_block_mode")
											option(v-for="o in geoBlockOptions", :value="o.v") {{ o.t }}

				.col-sm-3
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Summary
						.card-body
							dl
								dt Name
								dd {{ offer.name }}

								dt Platform, Geo
								dd {{ offer.platform }}, {{ offer.countries }}

								dt MMP
								dd {{ offer.mmp }}

								dt Remote Offer ID
								dd {{ offer.externalOffer ? offer.externalOffer.external_id : '' }}



</template>
<style lang="scss">
.schedule-table {
	//width: 1200px;
	th {
		text-align: center;
	}
	td {
		text-align: center;
	}
	td.d {
		width: 30px;
		text-align: center;
	}
	td.c {
		width: 30px;
	}
	input[type="text"] {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
		border: none;
		border-radius: 2px;
		background: #f6f6f6;
	}
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";
import DataService from "@/core/services/data.service";
import {_} from 'vue-underscore';


async function loadRoute(vm, to, from, next){

	let advertiserId = +to.query.advertiser_id;
	let offer = {};
	if (to.name === 'offer-edit') {
		let entityId = +to.params.id;
		try {
			offer = await Vue.$dwData.offer.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load offer');
			return next(false);
		}
	} else {

		if (+to.query.copy_offer_id){
			let offerId = +to.query.copy_offer_id;
			offer = await Vue.$dwData.offer.get(offerId, true);
			offer.id = 0;
		} else {

			let externalOfferId = +to.query.eo_id;
			if (externalOfferId) {
				offer = await Vue.$dwData.offer.prepareFromExternal(externalOfferId);
			} else {
				offer = Vue.$dwData.offer.newInstance();
				offer.advertiser_id = advertiserId;
			}
		}
	}
	let advertiser = null;
	if (offer.advertiser_id){
		advertiser = await Vue.$dwData.advertiser.get(offer.advertiser_id);
	}
	// Vue.ovData.advertiser._booleans.forEach(f => {
	// 	advertiser[f] = !!advertiser[f];
	// });

	let assetOptions = await Vue.$dwData.asset.getOptions(true);
	let offerOptions = [];
	let dataControlOptions = await Vue.$dwData.dataControl.getOptions(true);


	let countryOptions = await Vue.$dwData.country.getOptions();

	return vm => {
		// vm.publisherOptions = publisherOptions;
		// vm.integrationTypeOptions = integrations;
		vm.countryOptionsWL = countryOptions;
		vm.countryOptionsBL = JSON.parse(JSON.stringify(countryOptions));
		vm.dataControlOptions = dataControlOptions;
		vm.offerOptions = offerOptions;
		vm.assetOptions = assetOptions;
		vm.advertiser = advertiser;
		vm.offer = offer;
		let targeting = offer.targeting;
		if (!targeting){
			targeting = {};
		}
		if (!targeting.geo) {
			targeting.geo = {whitelist: [], blacklist: []};
		}
		if (!targeting.isp) {
			targeting.isp = {whitelist: [], blacklist: []};
		}
		if (!targeting.carrier) {
			targeting.carrier = {whitelist: [], blacklist: []};
		}
		vm.targeting = targeting;
		vm.targetingOnChangeCountryWL();
		vm.targetingOnChangeCountryBL();
		vm.offer.effective_status = null;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'OfferForm',
	computed: {
		isComplex(){
			return this.offer.type === 'asset' || this.offer.type === 'custom';
		}
	},
	data(){
		return {
			$_debounceTimer: null,
			busy: true,
			offer: null,
			days: ['S','M','T','W','T','F','S'],
			countryOptionsWL: [],
			countryOptionsBL: [],
			wl_regionOptions: {},
			wl_cityOptions: {},
			bl_regionOptions: {},
			bl_cityOptions: {},
			offerTypeOptions: Vue.$dwData.general.offerTypeOptions,
			offerStatusOptions: Vue.$dwData.general.offerStatusOptions,
			offerEffectiveStatusOptions: [
				{v: null, t: '- Auto -'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
			],
			currencyOptions: Vue.$dwData.general.currencyOptions,
			platformOptions: Vue.$dwData.general.platformOptions,
			timeZoneOptions: Vue.$dwData.general.timezoneOptions,
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
			mmpOptions: Vue.$dwData.general.mmpOptions,
			copyPayoutPercent: 70,
			assetOptions: [],
			offerOptions: [],
			dataControlOptions: [],
			assetOverrideList: [],
			targeting: null,
			geoBlockOptions: [
				{v: 'block', t: 'Block mismatch'},
				{v: 'prioritize', t:'Prefer matching offer'}
			],

			ispOptions: [],
			isLoadingISP: false,
			carrierOptions: [],
			isLoadingCarrier: false,
			attrFormatOptions: [
				{v: 0, t:'dw'},
				{v: 1, t:'oo_'},
				{v: 2, t:'fit_'},
				{v: 3, t:'iqp'},
				{v: 4, t:'tsk_'},
			]
		};
	},
	methods: {

		// onSearch(search, loading) {
		// 	if(search.length) {
		// 		loading(true);
		// 		this.search(loading, search, this);
		// 	}
		// },

		selectRow(row){
			let v = this.offer.daily_schedule[row][0].enabled;
			for (let i=0; i<this.offer.daily_schedule[row].length; i++){
				this.offer.daily_schedule[row][i].enabled = v;
			}
		},
		selectCol(col){
			let v = !this.offer.daily_schedule[0][col].enabled;
			for (let i=0; i<this.offer.daily_schedule.length; i++) {
				this.offer.daily_schedule[i][col].enabled = v;
			}
		},
		// copyAll(index){
		// 	for (let i = 0; i < 7; i++) {
		// 		if (i !== index) {
		// 			for (let j = 0; j < 24; j++) {
		// 				this.offer.daily_schedule[i][j].enabled = this.offer.daily_schedule[index][j].enabled;
		// 			}
		// 		}
		// 	}
		// },
		// getDailySum(d){
		// 	let sum = 0;
		// 	if (!this.offer || !this.offer.daily_schedule){
		// 		return 0;
		// 	}
		// 	let sched = this.offer.daily_schedule;
		// 	if (sched[d]) {
		// 		for (let i = 0; i < 24; i++) {
		// 			sum += +sched[d][i].clicks;
		// 		}
		// 	}
		// 	return sum;
		// },

		_searchOffers: _.debounce(async(loading, search, vm) => {

			let params = {
				page: 1,
				limit: 20,
				keyword: search,
				// type: this.filters.type,
				// is_vta: this.filters.is_vta,
				// status: this.filters.status,
				// af_prt: this.filters.af_prt,
				// pid: this.filters.pid,
				// effective_status: this.filters.effective_status,
				// platform: this.filters.platform,
				// countries: this.filters.countries,
				// advertiser_id: this.filters.advertiser_id.map(r => r.v).join(','),
				// direct_only: this.filters.direct_only ? 1 : 0,
				// has_traffic: this.filters.has_traffic ? 1 : 0,
				network_id: vm.offer.network_id || 0,
			};
			try {
				let resp = await vm.$api.get('/offers/getList', {params});
				Vue.set(vm, 'offerOptions', resp.records);
				console.log(vm.offerOptions);
			} catch (e) {
				console.error(e);
			}
			loading(false);
		}, 350),


		async fetchOfferOptions(search, loading){
			if (search.length > 2){
				loading(true);
				this._searchOffers(loading, search, this);
			}
		},
		addGeoParam(){
			this.offer.geo_params.push({
				country: '',
				params: ''
			});
		},
		addEvent(){
			this.offer.events.push({
				name: '',
				payout: 0,
			});
		},
		addConnectedOffer(){
			this.offer.subOffers.push({
				child_id: null,
				weight: 0,
				enabled: false
			});
		},
		copyPayout(){
			this.offer.payout = +(((+this.copyPayoutPercent/100) * this.offer.externalOffer.payout).toFixed(2));
		},

		async targetingOnChangeCountryWL(){
			for (let i = 0; i < this.targeting.geo.whitelist.length; i++) {
				let cn = this.targeting.geo.whitelist[i];
				if (!cn.regions) {
					Vue.set(cn, 'regions', []);
				}
				if (!cn.cities) {
					Vue.set(cn, 'cities', []);
				}
				if (!this.wl_regionOptions[cn.code2]) {
					Vue.set(this.wl_regionOptions, cn.code2, await this.$dwData.region.getOptions(true, cn.code2));
				}
				if (!this.wl_cityOptions[cn.code2]) {
					Vue.set(this.wl_cityOptions, cn.code2, await this.$dwData.city.getOptions(true, cn.code2));
				}
			}
		},

		async targetingOnChangeCountryBL(){
			for (let i = 0; i < this.targeting.geo.blacklist.length; i++) {
				let cn = this.targeting.geo.blacklist[i];
				if (!cn.regions) {
					Vue.set(cn, 'regions', []);
				}
				if (!cn.cities) {
					Vue.set(cn, 'cities', []);
				}
				if (!this.bl_regionOptions[cn.code2]) {
					Vue.set(this.bl_regionOptions, cn.code2, await this.$dwData.region.getOptions(true, cn.code2));
				}
				if (!this.bl_cityOptions[cn.code2]) {
					Vue.set(this.bl_cityOptions, cn.code2, await this.$dwData.city.getOptions(true, cn.code2));
				}
			}
		},
		async reloadDataControl(){
			this.busy = true;
			try {
				let dataControlOptions = await Vue.$dwData.dataControl.getOptions(true);
				this.dataControlOptions = dataControlOptions;
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		},

		async loadAssetInfo(){
			if (this.busy){
				return;
			}
			if (!this.offer.preview_url){
				return;
			}
			this.busy = true;
			try {
				let data = {
					url: this.offer.preview_url
				};
				let resp = await this.$api.post('assets/fetch', data);
				console.log('RESP', resp);
				if (resp.asset_id){
					this.offer.asset_id = resp.asset_id;
					if (resp.is_new){
						this.assetOptions = await Vue.$dwData.asset.getOptions(true);
					}
				}
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		},

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.offer);
			if (!Array.isArray(data.subOffers)){
				data.subOffers = [];
			}
			data.subOffers.forEach(so => {
				if (so.child){
					so.child_id = so.child.id;
				} else {
					so.child_id = 0;
				}
			});
			try {
				let resp = await this.$api.post('offers/save', data);
				this.busy = false;
				this.$notify.success('Offer has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'offer-list'});
				} else {
					this.offer = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		},

		debounce (method, timer) {
			if (this.$_debounceTimer !== null) {
				clearTimeout(this.$_debounceTimer)
			}
			this.$_debounceTimer = setTimeout(() => {
				method()
			}, timer)
		},

		async debounceFindISP(query){
			this.debounce(async() => {
				this.isLoadingISP = true;
				let params = {
					params: {keyword: query}
				};
				let resp = await this.$api.get('dimensions/getISPOptions', params);
				this.ispOptions = resp.options;
				this.isLoadingISP = false;
			}, 200);
		},

		async debounceFindCarrier(query){
			this.debounce(async() => {
				this.isLoadingCarrier = true;
				let params = {
					params: { keyword: query}
				};
				let resp = await this.$api.get('dimensions/getCarrierOptions', params);
				this.carrierOptions = resp.options;
				this.isLoadingCarrier = false;
			}, 200);

		}
	},
	mounted(){
		this.$_debounceTimer = null
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Offers', name: 'offer-list'},
			{title: this.offer && this.offer.id ? 'Edit Offer' : 'Add Offer'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'offer-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
